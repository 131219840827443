import React from 'react';
import { Helmet } from 'react-helmet';

class Page extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showFlyout: true
    };
    this.flyoutShowHandler = this.flyoutShowHandler.bind(this);
    this.flyoutHideHandler = this.flyoutHideHandler.bind(this);
  }

  flyoutShowHandler(event) {
    this.setState({showFlyout: true});
  }

  flyoutHideHandler(event) {
    this.setState({showFlyout: false});
  }

  render() {
    return (
      <div {...this.props}>
        <Helmet>
          <title>Bathke Geisel Architekten - {this.props.title}</title>
          <meta property="og:locale" content="de_DE"/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content={this.props.title}/> 
          <meta property="og:url" content="https://bathke-geisel.de/"/>
          <meta property="og:site_name" content="Bathke Geisel Architekten"/>
          <meta property="og:image" content={this.props.image}/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content={this.props.image}/>
        </Helmet>
        {this.props.children}
      </div>
    );
  }
}

export default Page;