import React from "react";

export default class Video extends React.Component {
  constructor(props) {
    super(props);
    this.video = null;
    this.playButton = null;
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleCanPlay = this.handleCanPlay.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    if (this.video.paused) {
      this.handlePlay();
    } else {
      this.handlePause();
    }
  }

  handleCanPlay() {
    console.log("Can Play");
    // this.video.play();
  }

  handlePlay() {
    this.video.play();
  }

  handlePause() {
    this.video.pause();
  }

  componentDidMount() {
    this.video.load();
  }

  render() {
    return (
      <div className="video__container">
        <video
          className="project__video"
          ref={(v) => {
            this.video = v;
          }}
          muted={true}
          preload="metadata"
          loop={true}
          playsInline={true}
          src={this.props.src}
          onMouseEnter={this.handlePlay}
          onMouseLeave={this.handlePause}
          onCanPlay={this.handleCanPlay}
        >
          <source src={this.props.src} type="video/mp4" />
        </video>
        <button
          ref={(b) => {
            this.playButton = b;
          }}
          className="video__play"
          onClick={this.handleToggle}
        >
          Play video
        </button>
      </div>
    );
  }
}
