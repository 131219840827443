import React from "react";
import { Link, NavLink } from "react-router-dom";

const Flyout = (props) => {
  return (
    <section className={`flyout${props.show ? " show" : ""}`}>
      <header>
        <Link to="/">Bathke Geisel Architekten</Link>
      </header>

      <div>
        <button className="btn btn--close" onClick={props.hide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26.56"
            height="26.559"
            viewBox="0 0 26.56 26.559"
          >
            <path
              id="Pfad_7"
              data-name="Pfad 7"
              d="M697.574,65.36l-1.951-1.95L684.294,74.738,672.966,63.41l-1.951,1.95,11.329,11.329L671.015,88.017l1.951,1.951L684.294,78.64l11.329,11.329,1.951-1.951L686.245,76.689Z"
              transform="translate(-671.015 -63.41)"
              fill="#fff"
            />
          </svg>
        </button>
      </div>

      <nav className="nav nav--main">
        <ul>
          <li className="nav--main__item">
            <NavLink to="/gebautes">Gebautes</NavLink>
          </li>
          <li className="nav--main__item">
            <NavLink to="/aktuelles">Aktuelles</NavLink>
          </li>
          <li className="nav--main__item">
            <NavLink to="/buero">Büro</NavLink>
          </li>
          <li className="nav--main__item">
            <NavLink to="/kontakt">Kontakt</NavLink>
          </li>
        </ul>
      </nav>

      <div className="flyout__footer">
        <div className="flyout__address">
          <p>
            Bathke Geisel Architekten BDA
            <br />
            Westermühlstraße 37
            <br />D - 80469 München
          </p>
          <p>
            +49 (0)89 6230 3550
            <br />
            <a href="mailto:info@bathke-geisel.de">info@bathke-geisel.de</a>
          </p>
        </div>
        <nav>
          <a href="/impressum">Impressum</a> /{" "}
          <a href="/datenschutz">Datenschutz</a>
        </nav>
      </div>
    </section>
  );
};

export default Flyout;
