import React from "react";
import { withRouter } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import cockpit from "./utils/cockpit";
import Routes from "./Routes";
import ScrollToTop from "./components/ScrollToTop";
import "./scss/main.scss";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    cockpit.getSingleton("startseite").then((result) => {
      this.setState({ startseite: result.data });
    });
    cockpit.getSingleton("buero").then((result) => {
      this.setState({ buero: result.data });
    });
    cockpit.getSingleton("kontakt").then((result) => {
      this.setState({ kontakt: result.data });
    });
    cockpit.getSingleton("impressum").then((result) => {
      this.setState({ impressum: result.data });
    });
    cockpit.getSingleton("datenschutz").then((result) => {
      this.setState({ datenschutz: result.data });
    });
    cockpit.getCollection("projekte").then((res) => {
      this.setState({ projekte: res.data.entries });
    });
    cockpit.getCollection("aktuelles").then((res) => {
      this.setState({ aktuelles: res.data.entries });
    });
    // cockpit.getAktuelles().then((res) => {
    //   this.setState({ aktuelles: res.data.entries });
    // });
  }

  render() {
    let result = null;
    const {
      startseite,
      buero,
      projekte,
      aktuelles,
      kontakt,
      impressum,
      datenschutz,
    } = this.state;

    if (
      startseite &&
      buero &&
      projekte &&
      aktuelles &&
      kontakt &&
      impressum &&
      datenschutz
    ) {
      result = (
        <ScrollToTop>
          <TransitionGroup>
            <Routes data={this.state} />
          </TransitionGroup>
        </ScrollToTop>
      );
    }

    return <React.Fragment>{result}</React.Fragment>;
  }
}

export default withRouter(App);
