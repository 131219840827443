import React from "react";
import { withRouter } from "react-router-dom";
import Page from "../components/Page";
import Sidebar from "../components/Sidebar";
import Video from "../components/Video";

class Projekt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "alle",
      projects: this.props.data,
      containerClass: "",
    };

    this.scrollHandler = this.scrollHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollHandler);
    // document.querySelectorAll("video").forEach(function (video) {
    //   video.addEventListener("canplay", (event) => {
    //     console.log("Video can start now.");

    //     video.addEventListener("mouseenter", function (e) {
    //       e.target.play();
    //       console.log("PLAY");
    //     });

    //     video.addEventListener("mouseleave", function (e) {
    //       e.target.pause();
    //     });
    //   });
    // });
  }

  handleCategoryChange(category) {
    if (category !== "alle") {
      this.setState({
        projects: this.props.data.filter(
          (project) => project.category === category
        ),
        category: category,
      });
    } else {
      this.setState({
        projects: this.props.data,
        category: category,
      });
    }
  }

  scrollHandler(e) {
    if (window.pageYOffset > 10) {
      this.setState({ containerClass: "fadeOut" });
    } else {
      this.setState({ containerClass: "" });
    }
  }

  render() {
    const { data } = this.props;
    const project = data.filter(
      (item) => item.title_slug === this.props.match.params.slug
    )[0];

    let video01 = null;
    let video02 = null;

    if (project.video01_webm && project.video01_mp4) {
      video01 = (
        <Video
          src={`${process.env.REACT_APP_ROOT}/storage/uploads${project.video01_mp4.path}`}
        ></Video>
      );
    }

    if (project.video02_webm && project.video02_mp4) {
      video02 = (
        <Video
          src={`${process.env.REACT_APP_ROOT}/storage/uploads${project.video02_mp4.path}`}
        ></Video>
      );
    }

    return (
      <Page
        title="Projekte"
        className={`container project ${this.state.containerClass}`}
        image={`${process.env.REACT_APP_ROOT}/${project.overview_image.path}`}
      >
        <Sidebar hideFooter backLink="/gebautes" category={ this.props.location.category } slug={project.title_slug}>
          <div className="project__details">
            <h1 className="project__title">{project.title}</h1>
            <div
              className="project__data"
              dangerouslySetInnerHTML={{ __html: project.data }}
            ></div>
          </div>
        </Sidebar>

        <main>
          <div className="content" id="projekt-content">
            {project.gallery.map((image) => (
              <img
                className="project__image"
                src={`${process.env.REACT_APP_ROOT}/${image.path}`}
                alt=""
                key={image.path}
              />
            ))}

            {video01}
            {video02}

            <div className="project__description">
              <div
                dangerouslySetInnerHTML={{ __html: project.description }}
              ></div>
            </div>

            <div className="copyright">
              Bathke Geisel Architekten &copy; 2020 Alle Rechte vorbehalten
            </div>
          </div>
        </main>
      </Page>
    );
  }
}

export default withRouter(Projekt);
