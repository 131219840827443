import axios from "./axios";

const getSingleton = (singleton) => axios.get(`singletons/get/${singleton}?populate=1`);
const getCollection = (collection) =>
  axios.get(`collections/get/${collection}`);

// const getAktuelles = () =>
//   axios({
//     method: "post",
//     url: "singletons/get/aktuelles",
//     data: {
//       sort: { date: -1 },
//     },
//   });

export default {
  getSingleton: getSingleton,
  getCollection: getCollection,
};
