import React from "react";
import Page from "../components/Page";
import { ThemeProvider } from "styled-components";
import Sidebar from "../components/Sidebar";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 11, // rem
    outerMargin: 0, // rem
    mediaQuery: "only screen",
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 63, // em
      lg: 75, // em
    },
  },
};

const Aktuelles = (props) => {
  

  const news = props.data.sort((a, b) => {
    const da = new Date(a.date).getTime();
    const db = new Date(b.date).getTime();
    return db - da;
  });

  return (
    <Page title="Aktuelles" className="container default">
      <Sidebar hideFooter />
      <main>
        <div className="content">
          <ThemeProvider theme={theme}>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }}
            >
              <Masonry gutter="60px">
                {news.map((item) => {
                  const time = item.date.split("-");
                  return (
                    <article className="news__item" key={item.date}>
                      <img
                        className="news__image"
                        src={`${process.env.REACT_APP_ROOT}/${item.image.path}`}
                        alt={item.title}
                      />
                      {/* <time className="news__date" datetime={item.date}>{`${time[2]}. ${month[time[1]]} ${time[0]}`}</time> */}
                      <time
                        className="news__date"
                        dateTime={item.date}
                      >{`${time[1]}/${time[0]}`}</time>
                      <h2 className="news__headline">{item.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                    </article>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </ThemeProvider>

          <div className="copyright">
            Bathke Geisel Architekten &copy; 2020 Alle Rechte vorbehalten
          </div>
        </div>
      </main>
    </Page>
  );
};

export default Aktuelles;
