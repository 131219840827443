import React from 'react';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';

const Kontakt = props => {
  return (
    <Page title="Kontakt" className="container default contact">
      <Sidebar hideFooter>
        <div className="contact__address" dangerouslySetInnerHTML={{ __html: props.data.text }}>
          {/* <p>
            Bathke Geisel Architekten BDA<br/>
            Westermühlstraße 37<br/>
            D - 80469 München
          </p>
          <p>
            +49 (0)89 6230 3550<br/>
            <a href="mailto:info@bathke-geisel.de">info@bathke-geisel.de</a>
          </p> */}
        </div>
      </Sidebar>
      <main>
        <div className="content">
          <img src={`${process.env.REACT_APP_ROOT}/${props.data.map.path}`} alt="" />
        </div>
      </main>
    </Page>
  );
};

export default Kontakt;
