import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Startseite from "./pages/Startseite";
import Projekte from "./pages/Projekte";
import Projekt from "./pages/Projekt";
import Aktuelles from "./pages/Aktuelles";
import Buero from "./pages/Buero";
import Kontakt from "./pages/Kontakt";
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";
import NotFound from "./pages/NotFound";
import { CSSTransition } from "react-transition-group";



const Routes = (props) => {
  const data = props.data;

  return (
    <div>
      <CSSTransition
        in={true}
        key={props.location.key}
        classNames="fade"
        timeout={1000}
      >
        <Switch location={props.location}>
          <Route
            path="/"
            exact
            render={(props) => <Startseite {...props} data={data.startseite} />}
          />

          <Route
            path="/gebautes/:slug"
            render={(props) => <Projekt {...props} data={data.projekte} />}
          />

          <Route
            path="/gebautes"
            render={(props) => <Projekte {...props} data={data.projekte} />}
          />

          <Route
            path="/aktuelles"
            render={(props) => <Aktuelles {...props} data={data.aktuelles} />}
          />

          <Route
            path="/buero"
            render={(props) => <Buero {...props} data={data.buero} />}
          />

          <Route
            path="/kontakt"
            render={(props) => <Kontakt {...props} data={data.kontakt} />}
          />

          <Route
            path="/impressum"
            render={(props) => <Impressum {...props} data={data.impressum} />}
          />

          <Route
            path="/datenschutz"
            render={(props) => (
              <Datenschutz {...props} data={data.datenschutz} />
            )}
          />

          <Route render={() => <NotFound />} />
        </Switch>
      </CSSTransition>
    </div>
  );
};

export default withRouter(Routes);
