import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Page from "../components/Page";
import Sidebar from "../components/Sidebar";
import { ThemeProvider } from "styled-components";

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 4, // rem
    outerMargin: 4, // rem
    mediaQuery: "only screen",
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 63, // em
      lg: 75, // em
    },
  },
};

const Buero = (props) => {
  const { data } = props;
  data.publications.sort((a, b) => {
    let timeA = new Date(a.value.date).getTime();
    let timeB = new Date(b.value.date).getTime();
    if (timeA > timeB) return -1;
    if (timeA < timeB) return 1;
    return 0;
  });

  data.awards.sort((a, b) => {
    let timeA = new Date(a.value.date).getTime();
    let timeB = new Date(b.value.date).getTime();
    if (timeA > timeB) return -1;
    if (timeA < timeB) return 1;
    return 0;
  });

  data.contests.sort((a, b) => {
    let timeA = new Date(a.value.date).getTime();
    let timeB = new Date(b.value.date).getTime();
    if (timeA > timeB) return -1;
    if (timeA < timeB) return 1;
    return 0;
  });

  return (
    <Page title="Büro" className="container default buero">
      <Sidebar>
        <nav className="nav nav--sub">
          <ul>
            <li className="nav--sub__item">
              <AnchorLink href="#personen">Personen</AnchorLink>
            </li>
            <li className="nav--sub__item">
              <AnchorLink href="#veroeffentlichungen" target="_self">
                Veröffentlichungen
              </AnchorLink>
            </li>
            <li className="nav--sub__item">
              <AnchorLink href="#auszeichnungen" target="_self">
                Auszeichnungen
              </AnchorLink>
            </li>
            <li className="nav--sub__item">
              <AnchorLink href="#wettbewerbe" target="_self">
                Wettbewerbe
              </AnchorLink>
            </li>
          </ul>
        </nav>
      </Sidebar>
      <main>
        <div className="content">
          <img
            className="buero__mood"
            src={`${process.env.REACT_APP_ROOT}/${data.header_image.path}`}
            alt=""
          />

          <section id="personen">
            <h2>Personen</h2>

            <ThemeProvider theme={theme}>
              <article className="buero__person buero__gap">
                <Row className="buero__person--bathke">
                  <Col className="col" xs={12} md={12} lg={7} order-xs={1}>
                    <img
                      src={`${process.env.REACT_APP_ROOT}/${data.bathke_image.path}`}
                      alt={data.persons[0].value.name}
                    />
                  </Col>
                  <Col className="col" xs={12} md={12} lg={5} order-xs={-1}>
                    <strong>Steffen Bathke</strong>
                    <br />
                    <div className="table__body table__body--cv">
                      {data.bathke_cv.map((item) => (
                        <div className="row">
                          <div>{item.value.year}</div>
                          <div>{item.value.text}</div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </article>

              <article className="buero__person buero__gap">
                <Row className="buero__person--geisel">
                  <Col className="col" xs={12} md={12} lg={5}>
                    <strong>Lutz Geisel</strong>
                    <br />
                    <div className="table__body table__body--cv">
                      {data.geisel_cv.map((item) => (
                        <div className="row">
                          <div>{item.value.year}</div>
                          <div>{item.value.text}</div>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col className="col" xs={12} md={12} lg={7}>
                    <img
                      src={`${process.env.REACT_APP_ROOT}/${data.geisel_image.path}`}
                      alt={data.persons[1].value.name}
                    />
                  </Col>
                </Row>
              </article>

              <div class="buero__gap">
                <img src={`${process.env.REACT_APP_ROOT}/${data.group_image.path}`} alt=""/>
              </div>

              <div class="buero__employee">
                <img class="buero__employee__image" src={`${process.env.REACT_APP_ROOT}/${data.employee_image.path}`} alt=""/>
                <div dangerouslySetInnerHTML={{ __html: data.employee_text }}></div>
              </div>
            </ThemeProvider>
          </section>

          <section id="veroeffentlichungen">
            <h2>Veröffentlichungen</h2>
            <div className="table__header"></div>
            <div className="table__body table__body--publications">
              {data.publications.map((item) => (
                <div className="row">
                  <div>{item.value.source}</div>
                  <div>{item.value.title}</div>
                </div>
              ))}
            </div>
          </section>

          <section id="auszeichnungen">
            <h2>Auszeichnungen</h2>
            <div className="table__header"></div>
            <div className="table__body table__body--awards">
              {data.awards.map((item) => (
                <div className="row">
                  <div>{item.value.title}</div>
                  <div>{item.value.projectname}</div>
                  <div>{item.value.award}</div>
                </div>
              ))}
            </div>
          </section>

          <section id="wettbewerbe">
            <h2>Wettbewerbe</h2>
            <div className="table__header"></div>
            <div className="table__body table__body--contests">
              {data.contests.map((item) => (
                <div className="row">
                  <div>{item.value.datetext}</div>
                  <div>{item.value.contest}</div>
                  <div>{item.value.result}</div>
                </div>
              ))}
            </div>
          </section>

          <section class="buero__footer">
            <img src={`${process.env.REACT_APP_ROOT}/${data.footer_image.path}`} alt=""/>
          </section>

          <section id="photocopyright">
            <div dangerouslySetInnerHTML={{ __html: data.photo_copyright }}></div>
          </section>

          <div className="copyright">
            Bathke Geisel Architekten &copy; 2020 Alle Rechte vorbehalten
          </div>
        </div>
      </main>
    </Page>
  );
};

export default Buero;
