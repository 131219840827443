import React from "react";
import { Link, withRouter } from "react-router-dom";
import Page from "../components/Page";
import Sidebar from "../components/Sidebar";

class Projekte extends React.Component {
  constructor(props) {
    super(props);

    // const projectsSorted = this.props.data.sort((a, b) => {
    //   const da = new Date(a.date);
    // });

    this.state = {
      category: "alle",
      projects: this.props.data
    };
  }

  handleCategoryChange(category, ret = false) {
    if (category !== "alle") {
      this.setState({
        projects: this.props.data.filter(
          // (project) => project.category === category
          (project) => project.category.includes(category)
        ),
        category: category,
      });
    } else {
      this.setState({
        projects: this.props.data,
        category: category,
      });
    }

    window.scrollTo(0, 0);
    
    // if (!ret) {
    //   if (window.location.href.split('#')[1]) {
    //     window.location = window.location.href.split('#')[0];
    //   }
    // } 
  }

  componentDidMount() {
    
    if (this.props.location.category) {
      const category = this.props.location.category;
      this.setState({
        category: category
      });

      this.handleCategoryChange(category, true);
    }

    const { hash } = window.location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  }

  render() {
    let projects = null;

    if (this.state.projects.length > 0) {
      projects = this.state.projects.map((project) => (
        <div className="teaser" key={project.title_slug}>
          {/* <Link to={`/gebautes/${project.title_slug}`}> */}
          <Link to={{
            pathname: `/gebautes/${project.title_slug}`,
            category:  this.state.category
          }}>
            <img
              className="teaser__image"
              src={`${process.env.REACT_APP_ROOT}/${project.overview_image.path}`}
              alt={project.title}
              id={project.title_slug}
            />
            <h3 className="teaser__title">{project.title}</h3>
          </Link>
        </div>
      ));
    } else {
      projects = <h3>Es gibt in dieser Kategorie noch keine Projekte</h3>;
    }

    return (
      <Page title="Projekte" className="container projects">
        <Sidebar hideFooter>
          <nav className="nav nav--sub">
            <ul>
              <li
                onClick={() => this.handleCategoryChange("alle")}
                className={
                  this.state.category === "alle"
                    ? "nav--sub__item active"
                    : "nav--sub__item"
                }
              >
                Alle
              </li>
              <li
                onClick={() => this.handleCategoryChange("wohnbauten")}
                className={
                  this.state.category === "wohnbauten"
                    ? "nav--sub__item active"
                    : "nav--sub__item"
                }
              >
                Wohngebäude
              </li>
              <li
                onClick={() => this.handleCategoryChange("gewerbebauten")}
                className={
                  this.state.category === "gewerbebauten"
                    ? "nav--sub__item active"
                    : "nav--sub__item"
                }
              >
                Gewerbegebäude
              </li>
              <li
                onClick={() => this.handleCategoryChange("oeffentliche_bauten")}
                className={
                  this.state.category === "oeffentliche_bauten"
                    ? "nav--sub__item active"
                    : "nav--sub__item"
                }
              >
                öffentliche Gebäude
              </li>
              <li
                onClick={() => this.handleCategoryChange("temporaere_bauten")}
                className={
                  this.state.category === "temporaere_bauten"
                    ? "nav--sub__item active"
                    : "nav--sub__item"
                }
              >
                temporäre Bauten
              </li>
              <li
                onClick={() => this.handleCategoryChange("moebel")}
                className={
                  this.state.category === "moebel"
                    ? "nav--sub__item active"
                    : "nav--sub__item"
                }
              >
                Möbel
              </li>
            </ul>
          </nav>
        </Sidebar>
        <main>
          <div className="content">
            {projects}

            <div className="copyright">
              Bathke Geisel Architekten &copy; 2020 Alle Rechte vorbehalten
            </div>
          </div>
        </main>
      </Page>
    );
  }
}

export default withRouter(Projekte);
