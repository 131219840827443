import React from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-sticky-el";
import Flyout from "./Flyout";
import { HashLink } from 'react-router-hash-link';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFlyout: false,
    };
    this.flyoutShowHandler = this.flyoutShowHandler.bind(this);
    this.flyoutHideHandler = this.flyoutHideHandler.bind(this);
    this.buttonBurgerElement = null;
  }

  flyoutShowHandler(event) {
    this.setState({ showFlyout: true });
  }

  flyoutHideHandler(event) {
    this.setState({ showFlyout: false });
  }

  render() {
    let toggle = (
      <Sticky className="sticky" onClick={this.flyoutShowHandler}>
        <div className="btn--burger__container">
          <button className="btn btn--burger">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="12"
              viewBox="0 0 31.128 11.994"
            >
              <g id="Gruppe_12" data-name="Gruppe 12">
                <rect
                  id="Rechteck_7"
                  data-name="Rechteck 7"
                  width="31"
                  height="3"
                  fill="#3c3c3c"
                />
                <rect
                  id="Rechteck_8"
                  data-name="Rechteck 8"
                  width="31"
                  height="3"
                  transform="translate(0 9)"
                  fill="#3c3c3c"
                />
              </g>
            </svg>
          </button>
        </div>
      </Sticky>
    );

    let close = null;

    let footer = (
      <footer>
        <p>
          Bathke Geisel Architekten PartGmbB
          <br />
          Westermühlstraße 37
          <br />D - 80469 München
        </p>
        <p>
          +49 (0)89 6230 3550
          <br />
          <a href="mailto:info@bathke-geisel.de">info@bathke-geisel.de</a>
        </p>

        <nav className="nav--footer">
          <NavLink to="/impressum">Impressum</NavLink> /{" "}
          <NavLink to="/datenschutz">Datenschutz</NavLink>
        </nav>
      </footer>
    );

    if (this.props.hideFooter) {
      footer = null;
    }

    if (this.props.backLink) {
      close = (
        <div className="btn--close__container">
          {/* <Link to={this.props.backLink} className="btn btn--close"> */}
          <Link to={{
            pathname: this.props.backLink,
            category: this.props.category || null,
            hash: this.props.slug,
          }} className="btn btn--close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26.56"
              height="26.559"
              viewBox="0 0 26.56 26.559"
            >
              <path
                id="Pfad_7"
                data-name="Pfad 7"
                d="M697.574,65.36l-1.951-1.95L684.294,74.738,672.966,63.41l-1.951,1.95,11.329,11.329L671.015,88.017l1.951,1.951L684.294,78.64l11.329,11.329,1.951-1.951L686.245,76.689Z"
                transform="translate(-671.015 -63.41)"
                fill="#3c3c3c"
              />
            </svg>
          </Link>
        </div>
      );
    }

    return (
      <React.Fragment>
        <aside className="sidebar">
          <header>
            <Link to="/" className="sidebar__logo">
              Bathke Geisel Architekten
            </Link>
            {close}
          </header>

          {toggle}
          {this.props.children}

          {footer}
        </aside>
        <Flyout show={this.state.showFlyout} hide={this.flyoutHideHandler} />
      </React.Fragment>
    );
  }
}

export default Sidebar;
