import React from "react";
import { Link, withRouter } from "react-router-dom";
import AutoFitImage from "react-image-autofit-frame";
import Page from "../components/Page";
import Sidebar from "../components/Sidebar";
import Hammer from 'hammerjs';

class Startseite extends React.Component {
  constructor(props) {
    super(props);
    this.state = { image: null, project: null };
    this.handleScroll = this.handleScroll.bind(this);

    this.manager = new Hammer.Manager(window);
    this.pan = new Hammer.Pan();
  }

  componentDidMount() {
    window.addEventListener("wheel", this.handleScroll);

    //let manager = new Hammer.Manager(window);
    //let Pan = new Hammer.Pan();
    this.manager.add(this.pan);
    let deltaY = 0;

    let that = this;

    this.manager.on('pan', function(e) {
      deltaY = deltaY + e.deltaY;
      var direction = e.offsetDirection;
      
      if (direction === 8 && deltaY < 10) {
        that.props.history.push("/gebautes");
      }
    });
    // window.addEventListener("touchmove", e => {
    //   console.log(e);
    // });

    const projectCount = this.props.data.projects.length;
    const randomIndex = Math.floor(Math.random() * projectCount); 

    let randProject = null;

    const img = new Image();
    randProject = this.props.data.projects[randomIndex];
    this.setState({
      project: randProject
    });
    img.src = `${process.env.REACT_APP_ROOT}/${this.props.data.projects[randomIndex].overview_image.path}`;
    img.addEventListener("load", () => {
      this.setState({
        image: `${process.env.REACT_APP_ROOT}/${this.props.data.projects[randomIndex].overview_image.path}`,
      });
    });

    // const imageCount = this.props.data.images.length;
    // const randomIndex = Math.floor(Math.random() * imageCount);

    // let randomImage = null;

    // const img = new Image();
    // img.src = `${process.env.REACT_APP_ROOT}/${this.props.data.images[randomIndex].path}`;
    // img.addEventListener("load", () => {
    //   randomImage = img;

    //   console.log(img);
    //   this.setState({
    //     image: `${process.env.REACT_APP_ROOT}/${this.props.data.images[randomIndex].path}`,
    //   });
    // });
  }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.handleScroll);
    this.manager.off('pan');
  }

  handleScroll(event) {
    if (event.deltaY > 5) {
      this.props.history.push("/gebautes");
    }
  }

  render() {
  
    let content = null;

    if (!this.state.image) {
      content = (<div className="lds-ring"><div></div><div></div><div></div><div></div></div>);
    } else {
      content = (
        <Link to={`/gebautes/${this.state.project?.title_slug}`}>
          <AutoFitImage
            imgSrc={this.state.image}
            imgSize="contain"
            positionY="top"
            positionX="left"
            style={{}}
          />
        </Link>
      );
    }

    return (
      <Page title="Startseite" className="container home" image={ 'https://bathke-geisel.de/social_preview.png' }>
        <Sidebar />
        <main>
          <div className="content">
            {content}
          </div>
        </main>
      </Page>
    );
  }
}

export default withRouter(Startseite);
