import React from 'react';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';

const NotFound = props => {

  return (
    <Page title="Datenschutz" className="container default">
      <Sidebar /> 
      <main>
        <div className="content">
          <h1>Seite nicht gefunden</h1>
        </div>
      </main>
    </Page>
  );
};

export default NotFound;