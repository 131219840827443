import React from "react";
import Sidebar from "../components/Sidebar";
import Page from "../components/Page";

const Impressum = (props) => {
  const { data } = props;

  return (
    <Page title="Datenschutz" className="container default">
      <Sidebar />
      <main>
        <div className="content">
          <h1>Impressum</h1>
          <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
        </div>
      </main>
    </Page>
  );
};

export default Impressum;
